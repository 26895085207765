#body-wrapper > .container {
  max-height: 90vh;
  text-align: center;
  vertical-align: middle;
  padding: 0;
  padding-bottom: 3rem;

  @media (min-width: $size-xl) {
    width: 100%;
    max-width: 66%;
  }
}
#body-wrapper > .container > p {
  // text-align: right;
  margin-bottom: 0;

  @media (max-width: $size-md) {
    padding-top: 1.7rem;
    padding-bottom: 3rem;
  }
}

.type-pad {
  margin: .4rem 0;
}
