// Layout
$horiz-padding: 1rem;
$vert-padding: 2rem 0 2rem;

// Fonts
$min-responsive-font-size: 16px;
$title-font-family: $base-font-family, $fallback-font-family !default;

// Header
$header-height-large: 85px;
$header-height-small: 45px;
$header-color-dark: #222;
$header-text-light: $light-color;
$header-text-dark: darken($dark-color, 15%);

// Dropdown Menu
$dropmenu-bg: $light-color;
$dropmenu-hover-text: $primary-color;
$dropmenu-horiz-padding: 5px;
$dropmenu-vert-padding: 7px;
$dropmenu-child-padding: 10px;
$dropmenu-shadow: 0 3px 5px rgba(0, 0, 0, 0.1);

// Mobile Menu
$mobile-color-main: $primary-color;
$mobile-color-active: #3F7443;
$mobile-color-link: #3F7443;
$mobile-button-height: 24px;
$mobile-button-width: 28px;
