// Sticky Footer solution
body.sticky-footer {
  height: 100%;
  width: 100vw;
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  #page-wrapper {
    flex: 1 0 auto;
  }
}

#footer {
  color: #acb3c2;
  padding: 0.6rem $horiz-padding 0;
  text-align: left;
  padding-bottom: 1.6rem;
  position: fixed;
  left: 0px;
  bottom: 0px;
  height: 3em;

}
